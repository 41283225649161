import CryptoJS from 'crypto-js'


export function encrypt(data,key) {
    var realKey = getKey(key);
    var encrypt = CryptoJS.AES.encrypt(data, CryptoJS.enc.Hex.parse(realKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypt.ciphertext.toString(CryptoJS.enc.Base64);
}

export function decrypt(data,key) {
    var realKey = getKey(key);
    let decData =  CryptoJS.enc.Base64.parse(data);
    var decrypt = CryptoJS.AES.decrypt({
        ciphertext: decData
    }, CryptoJS.enc.Hex.parse(realKey), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
}

function getKey(key) {
    var realKey = CryptoJS.SHA1(key);
    realKey = CryptoJS.SHA1(realKey).toString().substring(0, 32); //真正的key
    return realKey;
}
